import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

const PricingTable = () => {

    return (

                <div class="section-content">
                                        <div class="row g-gs justify-content-center">
                                            <div class="col-lg-4 col-md-6">
                                                <div class="pricing pricing-featured text-black">
                                                    <div class="pricing-body p-5">
                                                        <div class="text-center">
                                                            <h5 class="mb-3">Trial</h5>
                                                            <h3 class="h2">$0 <span class="caption-text text-muted"> / month</span></h3>
                                                            <p className="text-sm text-gray-600 mt-1">For 14 days</p>
                                                            <a href="//app.faceview.io/trial" class="btn btn-primary btn-soft btn-block">Sign up Today</a>
                                                        </div>
                                                        <ul class="list gy-3 pt-4 ps-2">
                                                            <li><em class="icon ni ni-check text-success"></em> <span><strong>1</strong> Active Job (Trial for 14 days)</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>5 Interviews per Month</span></li>
                                                           {/* <li><em class="icon ni ni-check text-success"></em> <span><strong>1</strong> User</span></li>*/}
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Your Own Job Board</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Chat Technical Support</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Newest Features</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="pricing pricing-featured bg-primary text-black">
                                                    <div class="pricing-body p-5">
                                                        <div class="text-center">
                                                            <h5 class="mb-3">Enterprise</h5>
                                                            <h3 class="h2">$99 <span class="caption-text text-muted"> / month</span></h3>
                                                            <p className="text-sm text-gray-600 mt-1">+ applicable tax</p>
                                                            <a href="//app.faceview.io/trial" class="btn btn-primary btn-block">Sign up Today</a>
                                                        </div>
                                                        <ul class="list gy-3 pt-4 ps-2">
                                                            <li><em class="icon ni ni-check text-success"></em> <span><strong>10</strong> Active Jobs</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>100 Interviews per Month</span></li>
                                                            {/*<li><em class="icon ni ni-check text-success"></em> <span><strong>5</strong> Users</span></li>*/}
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Your Own Job Board</span></li>
                                                            {/*<li><em class="icon ni ni-check text-success"></em> <span>100 Talent Pools</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Premium Templates</span></li>*/}
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Chat Technical Support</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Newest Features</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div class="col-lg-4 col-md-6">
                                                <div class="pricing">
                                                    <div class="pricing-body p-5">
                                                        <div class="text-center">
                                                            <h5 class="mb-3">Enterprise</h5>
                                                            <h3 class="h2">$199 <span class="caption-text text-muted"> / month</span></h3>
                                                            <p className="text-sm text-gray-600 mt-1">+ applicable tax</p>
                                                            <a href="//app.faceview.io/trial" class="btn btn-primary btn-soft btn-block">Sign up Today</a>
                                                        </div>
                                                        <ul class="list gy-3 pt-4 ps-2">
                                                            <li><em class="icon ni ni-check text-success"></em> <span><strong>50</strong> Active Jobs</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span><strong>50</strong> Candidates Per Job</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span><strong>10</strong> Users</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>100 Talent Pools</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Premium Templates</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Chat Technical Support</span></li>
                                                            <li><em class="icon ni ni-check text-success"></em> <span>Newest Features</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>

    )
}

export default PricingTable;