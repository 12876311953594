import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";


import NavBar from './navbar'
import Footer from './footer'

const Features = () => {

    useEffect(()=>{
          document.title = 'Features';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                                    <NavBar/>
                                     <div style={{minHeight: '70px'}}></div>
                                    <main class="nk-pages">
<section class="section section-bottom-0 has-shape has-mask">
                <div class="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-8 col-xl-7 col-xxl-6">
                                <h6 class="overline-title text-primary">Features</h6>
                                <h2 class="title h1">Maximize Hiring Success <span class="text-primary">Explore Our Features</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs">
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <div class=" d-flex align-items-end">
                                                        <em class="icon ni ni-dashboard"></em>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Your Own Job Board</h3>
                                                <p>
                                                 Faceview provides you a Job Board, to showcase all the open positions within the organization, making it easy for candidates to apply to the jobs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-spark"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">AI Generated Questions</h3>
                                                <p>Use AI to generate relevant interview questions automatically, ensuring a consistent and targeted experience for every candidate.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-video"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Video Interviews</h3>
                                                <p>Asynchronous video interviews allows candidates to record their responses to the questions at their convenience, without the need for real-time interaction.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <div class=" d-flex align-items-end">
                                                        <em class="icon ni ni-book"></em>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Review Responses</h3>
                                                <p>After candidates complete their interviews, review the video recordings in a scrollable interface helping you evaluate a large number of candidates.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-calendar"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Callback Meetings</h3>
                                                <p>For standout candidates, schedule callback meetings via phone, in-person, or Google Meet, with direct booking from the shared calendar, ensuring seamless scheduling.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl media-border text-bg-info-soft-outline rounded-pill">
                                                    <em class="icon ni ni-globe"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text">
                                                <h3 class="title">Meet the Best Candidates</h3>
                                                <p>
                                                  By meeting only with top candidates, employers streamline the hiring process, saving valuable time and focusing on the best talent.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
                                     </main>
                                    <Footer/>
                                </div>

    )
}

export default Features;