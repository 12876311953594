import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import NavBar from './navbar'
import Footer from './footer'

const UseCases = () => {

    useEffect(()=>{
      document.title = 'Use Cases';
      window.scrollTo(0,0);
    },[])

    return (

                <div className="nk-app-root ">
                <NavBar/>
                <div style={{minHeight: '70px'}}></div>
                <main class="nk-pages">
                <section class="section section-bottom-0 has-shape has-mask">
                <div class="nk-shape bg-shape-blur-m mt-8 start-50 translate-middle-x"></div>
                <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-8">
                                <h6 class="overline-title text-primary">Use cases</h6>
                                <h1 class="title">From Job Postings to Top Talent <span class="text-primary">Solutions for Every Business</span></h1>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row text-center g-gs">
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-flag"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Initial Screenings</h3>
                                                <p>Evaluate candidate's
                                                 communication skills, professionalism, and cultural fit efficiently, allowing you to shortlist the most promising candidates for further stages</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-globe"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Freelancer Hiring</h3>
                                                <p class="font-bold">Faceview allows freelancers to respond to the interview questions at their convenience.
                                                You can review the responses and schedule meetings with the best fits.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-video"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Video Auditions</h3>
                                                <p>Conduct video auditions to get a better sense of an artist's performance. Save time and effort by narrowing down your choices before in person auditions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-briefcase"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Internship Evaluation</h3>
                                                <p class="font-bold">
                                                 FaceView can streamline the selection process for internship programs by allowing
                                                 recruiters to evaluate applicants through asynchronous video interviews.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-user"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Volunteer Evaluation</h3>
                                                <p>
                                                FaceView can be used to evaluate volunteers by providing a platform
                                                to express their motivation, previous volunteer experience, and personal qualities.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-diamond"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Campus Jobs</h3>
                                                <p>FaceView can be a powerful tool for evaluating students applying for campus jobs,
                                                such as roles in student services, library assistance, or event coordination.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
                                     </main>
                                    <Footer/>
                                </div>

    )
}

export default UseCases;