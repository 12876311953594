import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";


import NavBar from './navbar'
import Footer from './footer'
import Card from './card'

const Company = () => {

    useEffect(()=>{
          document.title = 'Company';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                                    <NavBar/>
                                    <div style={{minHeight: '70px'}}></div>
                                   <main class="nk-pages">
                                               <section class="section has-shape has-mask text-gray-900" style={{paddingBottom: '0px'}}>
                                                   <div class="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                                                   <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                                                   <div class="container">
                                                       <div class="section-head">
                                                           <div class="row justify-content-center text-center">
                                                               <div class="col-lg-9 col-xl-8 col-xxl-7">
                                                                   <h6 class="overline-title text-primary">About Us</h6>
                                                                   <h2 class="title h1">The Story Behind Our Platform</h2>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div class="section-content">
                                                           <div class="row g-gs justify-content-center align-items-center flex-lg-row-reverse">
                                                               {/*<div class="col-lg-6 col-md-11">
                                                                   <div class="block-gfx ps-xxl-5">
                                                                       <img class="w-100" src="images/gfx/about.png" alt=""/>
                                                                   </div>
                                                               </div>*/}
                                                               <div class="col-lg-12">
                                                                   <div class="block-text">
                                                                      <ul class="list gy-3">
                                                                           <li>
                                                                             <p >At FaceView, we understand that talent evaluation is often a time-consuming and labor-intensive process. Employers are faced with the challenge of sorting through resumes, scheduling interviews, and engaging with candidates who may not be the right fit. This is where we come in.
                                                                             </p>

                                                                           </li>
                                                                         <li><p>
                                                                         We’re a team of passionate individuals dedicated to transforming the hiring process with the power of AI. Our platform was built with small business owners, hiring agencies, and brands in mind—those who are overwhelmed with the complexities of evaluating candidates or influencers at scale.

                                                                         </p></li>


                                                                           <li><p>

                                                                           For small business owners, especially those without an HR department, we streamline the hiring process so that you can focus on what matters most—running your business. Hiring agencies can now effectively manage large volumes of candidates, especially during high-pressure times like campus recruitment. And for brands looking to partner with influencers, FaceView helps marketing teams evaluate content creators in bulk, saving valuable time while ensuring the right fit for your brand.
                                                                           </p></li>
                                                                           <li><p>
We’re here to help you save time, reduce effort, and make better, data-driven decisions. Let FaceView transform your hiring and evaluation process today!

                                                                           </p></li>

                                                                       </ul>
                                                                       <ul class="btn-list btn-list-inline gy-0">
                                                                           <li><a href="//app.faceview.io/trial" class="btn btn-lg btn-primary"><span>Sign up for Free</span><em class="icon ni ni-arrow-long-right"></em></a></li>
                                                                       </ul>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </section>
                                               {/*<section class="section section-sm section-0">
                                                   <div class="container">
                                                       <div class="section-head">
                                                           <div class="row justify-content-center text-center">
                                                               <div class="col-lg-9 col-xl-8 col-xxl-6">
                                                                   <h2 class="title">Who uses our product</h2>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div class="section-content">
                                                           <ul class="list list-row justify-content-center justify-content-lg-between gx-5 gy-4">
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-a.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-b.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-c.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-d.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-e.png" alt=""/>
                                                               </li>
                                                           </ul>
                                                       </div>
                                                   </div>
                                               </section>*/}
                                               <section class="section section-bottom-0 has-shape">
                                                   <div class="nk-shape bg-shape-blur-m ms-n8 start-50 top-50 ms-n25 translate-middle-y"></div>
                                                   <div class="container">
                                                       <div class="section-content">
                                                           <div class="row gx-gs gy-6 justify-content-center align-items-center">
                                                               <div class="col-xl-5 col-lg-8">
                                                                   <div class="block-text pe-xxl-7 text-center text-xl-start">
                                                                       <h2 class="title">How Asynchronous Video Interview Works?</h2>
                                                                       <p className="lead">
                                                                        It is a video interview where no interviewer is present. Instead, a set of questions is displayed on the screen, and candidates respond as if they are in a real interview.
                                                                        </p>

                                                                        <p className="lead">
                                                                       This eliminates the need for employers to spend time talking to candidates directly Instead, candidates complete their video interviews on their own schedule.
                                                                        </p>

                                                                        <p className="lead">
                                                                        Once the candidates have completed their interviews, employers can review the video recordings in a scrollable interface, and schedule callback meetings with top-performing candidates.
                                                                        </p>
                                  <p class="lead">
It could be a phone call, an onsite meeting or a virtual meeting using Google Meet.This is how Faceview helps employers to efficiently connect with top-performing candidates.
                                                                        </p>

                                                                                                                                                <ul class="btn-list btn-list-inline gy-0">
                                                                           <li><a href="//app.faceview.io/trial" class="btn btn-lg btn-primary"><span>Sign up for Free</span><em class="icon ni ni-arrow-long-right"></em></a></li>
                                                                       </ul>
                                                                   </div>
                                                               </div>
                                                               <div class="col-xl-7">
                                                                   <div class="row">
                                                                       <div class="col-sm-6">
                                                                           <div class="card rounded-4 border-0 shadow-sm">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-primary rounded-3">
                                                                                               <em class="icon ni ni-globe"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                       <div class="feature-text">
                                                                                         <h3 class="title">Global Talent Access</h3>
                                                                                         <p>Overcome geographical barriers with FaceView. Conduct interviews with candidates from around the world, enabling you to tap into a diverse pool of talent without the limitations of physical proximity</p>
                                                                                     </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                           <div class="card rounded-4 border-0 shadow-sm mt-gs">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-success rounded-3">
                                                                                               <em class="icon ni ni-spark"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                       <div class="feature-text">
                                                                                           <h3 class="title">AI-Powered Questions</h3>
                                                                                           <p>Our AI-powered platform generates intelligent and relevant questions that spark insightful discussions.
                                                                                           Welcome to a new era of meaningful engagement.
                                                                                           </p>
                                                                                       </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                       <div class="col-sm-6 mt-5">
                                                                           <div class="card rounded-4 border-0 shadow-sm">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-info rounded-3">
                                                                                               <em class="icon ni ni-dashboard"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                       <div class="feature-text">
                                                                                           <h3 class="title">Your Own Job Board</h3>
                                                                                           <p> Our platform features an integrated Job Board, enabling employers to showcase all the open positions within the organization, making it easy for candidates to apply through customized application forms.
                                                                                                                                              </p>
                                                                                       </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                           <div class="card rounded-4 border-0 shadow-sm mt-gs">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-primary rounded-3">
                                                                                               <em class="icon ni ni-users"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                      <div class="feature-text">
                                                                                         <h3 class="title">Callback Meetings</h3>
                                                                                         <p>
                                                                                         For candidates who stand out, FaceView facilitates callback meetings via phone, in-person, or Google Meet. Employers can set their availability, and candidates can book directly from the shared calendar, ensuring seamless scheduling
                                                                                         </p>
                                                                                     </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </section>
                                               {/*<section class="section section-bottom-0 has-shape">
                                                   <div class="nk-shape bg-shape-blur-m mt-n3 start-50 top-0 translate-middle-x"></div>
                                                   <div class="container">
                                                       <div class="section-head">
                                                           <div class="row justify-content-center text-center">
                                                               <div class="col-lg-8 col-xl-7 col-xxl-6">
                                                                   <h6 class="overline-title text-primary">Careers</h6>
                                                                   <h2 class="title">View Job Openings</h2>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div class="section-content">
                                                           <div class="row g-gs justify-content-center align-items-center">
                                                                <iframe height="1000" src="https://jobs.faceview.io/faceview?mode=embed" title="W3Schools Free Online Web Tutorials"></iframe>

                                                           </div>
                                                       </div>
                                                   </div>
                                               </section>*/}
                                               <Card/>
                                           </main>
                                    <Footer/>
                                </div>

    )
}

export default Company;